body {
  color: #1b1b1b;
  cursor: none;
  height: 100dvh;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  overflow: hidden;
}

div#cursor {
  mix-blend-mode: exclusion;
  z-index: 9999;
  cursor: none;
  pointer-events: none;
  background: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  transition: width .5s cubic-bezier(.175, .885, .32, 1.8), height .5s cubic-bezier(.175, .885, .32, 1.8);
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

#pages {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  scroll-behavior: smooth;
  scrollbar-width: none;
  height: 100dvh;
  overflow-y: scroll;
}

#page1 {
  color: #fff;
  box-sizing: border-box;
  scroll-snap-align: start;
  background: #c9ffec;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
}

#page1 div#logo {
  perspective: 750px;
  width: 50vw;
  max-width: 1000px;
  max-height: 50dvh;
  position: relative;
}

#page1 div#logo[aria-checked="false"]:hover + div#cursor {
  width: 50px;
  height: 50px;
}

#page1 div#logo[aria-checked="false"] h1 {
  opacity: 0;
  left: -30%;
}

#page1 div#logo[aria-checked="false"] .slogan {
  opacity: 0;
  right: -20%;
}

#page1 div#logo[aria-checked="true"] h1 {
  opacity: 1;
  left: -10%;
}

#page1 div#logo[aria-checked="true"] .slogan {
  opacity: 1;
  right: -8%;
}

#page1 div#logo svg {
  animation: .5s cubic-bezier(.42, 1, .53, 1.2) fallFromSky;
}

#page1 div#logo #shadow {
  opacity: .2;
  z-index: -1;
  background: #000;
  border-radius: 100%;
  width: 59%;
  height: 18%;
  animation: .5s cubic-bezier(.42, 0, .53, 1.2) openUp;
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
}

#page1 div#logo h1 {
  color: #2c3e50;
  font-optical-sizing: auto;
  text-shadow: 0 0 1px #c9ffec, 10px 10px 20px #2c3e5099;
  z-index: 1;
  user-select: none;
  font-family: Urbanist, serif;
  font-size: 8vw;
  font-weight: 700;
  line-height: 0;
  transition: opacity .2s ease-in, left .6s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: -5%;
  left: -10%;
}

#page1 div#logo .slogan {
  color: #29505b;
  text-shadow: 0 0 1px #c9ffec, 10px 10px 20px #2c3e5099;
  z-index: 1;
  user-select: none;
  font-family: Poppins, serif;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  transition: opacity .2s ease-in .6s, right .5s cubic-bezier(.075, .82, .165, 1) .5s;
  position: absolute;
  top: 78%;
  right: -8%;
}

@keyframes fallFromSky {
  from {
    transform: translateY(-100vh)matrix(1, 0, 0, 3, 0, 0);
  }

  to {
    transform: translateY(0)matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes openUp {
  from {
    clip-path: ellipse(0% 0%);
    transform: translateX(-50%)scale(0);
  }

  to {
    clip-path: ellipse(70% 100%);
    transform: translateX(-50%)scale(1);
  }
}

#page2 {
  scroll-snap-align: start;
  background-color: #29505b;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
  display: flex;
  position: relative;
  overflow: hidden;
}

#page2 #circles-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#page2 #circles-container .circle {
  opacity: 0;
  background-color: #18ba9b;
  border-radius: 50%;
  transition: scale 1s ease-in-out, opacity 6s ease-out;
  position: absolute;
  box-shadow: 0 0 36px 41px #18ba9b;
}

#page2 .infoWrapper {
  max-width: 40vw;
  position: relative;
}

#page2 .infoWrapper #info {
  z-index: 1;
  background-color: #80b0ab;
  border: 1px solid #c9ffec;
  border-radius: 2rem;
  padding: 4rem;
  position: relative;
  box-shadow: 0 0 10px -3px #c9ffec;
}

#page2 .infoWrapper #info h2, #page2 .infoWrapper #info p {
  opacity: 0;
  visibility: hidden;
}

#page2 .infoWrapper #info h2 {
  margin: 0;
  padding: 0;
  font-size: 4rem;
}

#page2 .infoWrapper .infoDiv {
  z-index: 2;
  padding: 4rem;
  position: absolute;
  top: 0;
  left: 0;
}

#page2 .infoWrapper .infoDiv h2 {
  color: #2c3e50;
  text-shadow: 20px 20px 16px #0003;
  margin: 0;
  padding: 0;
  font-family: Urbanist, serif;
  font-size: 4rem;
  display: inline-block;
  position: relative;
}

#page2 .infoWrapper .infoDiv h2:after {
  content: "";
  background: #2c3e50;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 100%;
  right: 0;
  transform: translate(130%, -257%);
  box-shadow: 20px 20px 16px #0003;
}

#page2 .infoWrapper .infoDiv p {
  text-shadow: 10px 10px 10px #0003;
}

#page2 .infoWrapper .infoDiv p span.name {
  color: #2c3e50;
  font-family: Urbanist, serif;
  font-size: 1.2rem;
}

#page2 .infoWrapper #after {
  content: "";
  z-index: 0;
  z-index: 0;
  opacity: .8;
  background-color: #18bc9c;
  border: 1px solid #23e4bd;
  border-radius: 2rem;
  width: 75%;
  height: 65%;
  position: absolute;
  top: -10%;
  right: -4%;
  box-shadow: 0 0 10px -3px #18bc9c;
}

#page2 .infoWrapper #before {
  content: "";
  z-index: 0;
  z-index: 0;
  opacity: .8;
  background-color: #2c3e50;
  border: 1px solid #283a4d;
  border-radius: 2rem;
  width: 45%;
  height: 65%;
  position: absolute;
  top: 50%;
  left: -4%;
  box-shadow: 0 0 10px -3px #2c3e50;
}

#page2 a {
  cursor: none;
  color: #29505b;
  background-color: #18ba9b;
  border-radius: 1rem;
  margin: auto;
  padding: .75rem 3rem 4rem;
  font-size: 3rem;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  top: 100%;
  translate: 0 -60%;
}

@media (hover: none) {
  div#cursor {
    display: none;
  }
}

@media screen and (width <= 1200px) {
  #page2 .infoWrapper {
    max-width: 70vw;
  }

  #page2 .infoWrapper #info, #page2 .infoWrapper .infoDiv {
    padding: 2rem;
  }
}

@media screen and (width <= 750px) {
  #page1 div#logo {
    width: 75vw;
  }

  #page2 .infoWrapper {
    max-width: 90vw;
  }

  #page2 .infoWrapper #info, #page2 .infoWrapper .infoDiv {
    padding: 1rem 2rem;
  }
}

@media screen and (width <= 425px) {
  #page2 a {
    font-size: 2rem;
    translate: 0 -50%;
  }
}
/*# sourceMappingURL=index.da30478c.css.map */
