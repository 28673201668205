body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #1b1b1b;
  cursor: none;
  height: 100dvh;
  overflow: hidden;
}

div#cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 100%;
  mix-blend-mode: exclusion;
  z-index: 9999;
  transition: width .5s cubic-bezier(0.175, 0.885, 0.32, 1.8), height .5s cubic-bezier(0.175, 0.885, 0.32, 1.8);
  transform: translate(-50%, -50%);
  cursor: none;
  pointer-events: none;
}

#pages {
  scroll-behavior: smooth;
  height: 100dvh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

#page1 {
    width: 100%;
    background: #c9ffec;
    color: white;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    flex-direction: column;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    scroll-snap-align: start;
    
    div#logo {
      width: 50vw;
      max-width: 1000px;
      max-height: 50dvh;
      position: relative;
      perspective: 750px;

      &[aria-checked=false]:hover+div#cursor {
        width: 50px;
        height: 50px;
        
      }

      &[aria-checked=false] {

        h1 {
          opacity: 0;
          left: -30%;

        }

        .slogan {
          opacity: 0;
          right: -20%;

        }

      }

      &[aria-checked=true] {

        h1 {
          opacity: 1;
          left: -10%;

        }

        .slogan {
          opacity: 1;
          right: -8%;
          
        }

      }
      
      svg {
        animation: fallFromSky cubic-bezier(0.42, 1, 0.53, 1.2) .5s 1 normal;

      }

      #shadow {
        width: 59%;
        height: 18%;
        position: absolute;
        background: black;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.2;
        border-radius: 100%;
        z-index: -1;
        animation: openUp cubic-bezier(0.42, 0, 0.53, 1.2) .5s 1 normal;

      }

      h1 {
        position: absolute;
        top: -5%;
        left: -10%;
        font-size: 8vw;
        line-height: 0;
        font-weight: 700;
        color: #2c3e50;
        font-family: "Urbanist", serif;
        font-optical-sizing: auto;
        text-shadow: 0 0 1px #c9ffec, 10px 10px 20px rgb(44 62 80 / 60%);
        z-index: 1;
        user-select: none;
        transition: opacity .2s ease-in, left .6s cubic-bezier(0.075, 0.82, 0.165, 1);

      }

      .slogan {
        position: absolute;
        font-family: "Poppins", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 3.5vw;
        color: #29505b;
        text-shadow: 0 0 1px #c9ffec, 10px 10px 20px rgb(44 62 80 / 60%);
        right: -8%;
        top: 78%;
        z-index: 1;
        user-select: none;
        transition: opacity .2s ease-in .6s, right .5s cubic-bezier(0.075, 0.82, 0.165, 1) .5s;
      }

    }
    
    @keyframes fallFromSky {
      from { transform: translateY(-100vh) matrix(1, 0, 0, 3, 0, 0); }
      to { transform: translateY(0) matrix(1, 0, 0, 1, 0, 0); } 
    }

    @keyframes openUp {
      from { clip-path: ellipse(0% 0%); transform: translateX(-50%) scale(0);}
      to { clip-path: ellipse(70% 100%); transform: translateX(-50%) scale(1);}
    }
  
  }

  #page2 {
    width: 100%;
    height: 100dvh;
    background-color: #29505b;
    scroll-snap-align: start;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    #circles-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;

      .circle {
        position: absolute;
        border-radius: 50%;
        background-color: #18ba9b;
        opacity: 0;
        transition: scale 1s ease-in-out, opacity 6s ease-out;
        box-shadow: 0 0 36px 41px #18ba9b;
      }

    }
    .infoWrapper {
      position: relative;
      max-width: 40vw;

      #info {
        background-color: #80b0ab;
        border: 1px solid #c9ffec;
        z-index: 1;
        padding: 4rem;
        border-radius: 2rem;
        box-shadow: 0 0 10px -3px #c9ffec;
        position: relative;
  
        h2, p {
          opacity: 0;
          visibility: hidden;
        }

        h2 {
          font-size: 4rem;
          margin: 0;
          padding: 0;
        }
      }

      .infoDiv {
        padding: 4rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        h2 {
          font-family: 'Urbanist', serif;
          font-size: 4rem;
          margin: 0;
          padding: 0;
          color: #2c3e50;
          position: relative;
          display: inline-block;
          text-shadow: 20px 20px 16px rgba(0, 0, 0, 0.2);
  
          &::after {
            position: absolute;
            right: 0;
            top: 100%;
            content: '';
            width: 10px;
            height: 10px;
            background: #2c3e50;
            border-radius: 100%;
            transform: translate(130%, -257%);
            box-shadow: 20px 20px 16px rgba(0, 0, 0, 0.2);
          }
        }
  
        p {
          text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  
          span.name {
            font-family: 'Urbanist', serif;
            font-size: 1.2rem;
            color: #2c3e50;
          }
        }
      }

      #after {
        content: '';
        width: 75%;
        height: 65%;
        background-color: #18bc9c;
        position: absolute;
        z-index: 0;
        border-radius: 2rem;
        top: -10%;
        right: -4%;
        z-index: 0;
        box-shadow: 0 0 10px -3px #18bc9c;
        border: 1px solid #23e4bd;
        opacity: 0.8;
      }

      #before {
        content: '';
        width: 45%;
        height: 65%;
        background-color: #2c3e50;
        position: absolute;
        z-index: 0;
        border-radius: 2rem;
        top: 50%;
        left: -4%;
        z-index: 0;
        box-shadow: 0 0 10px -3px #2c3e50;
        border: 1px solid #283a4d;
        opacity: 0.8;
      }
    }

    a {
      position: absolute;
      top: 100%;
      cursor: none;
      margin: auto;
      color: red;
      font-size: 3rem;
      font-weight: 700;
      color: #29505b;
      background-color: #18ba9b;
      text-decoration: none;
      padding: .75rem 3rem;
      border-radius: 1rem;
      padding-bottom: 4rem;
      translate: 0 -60%;
    }
  }

  @media (hover: none) {
    div#cursor {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    #page2 .infoWrapper {
      max-width: 70vw;

      #info, .infoDiv {
        padding: 2rem;
      }
    }
  }

  @media screen and (max-width: 750px) {
    #page1 div#logo {
      width: 75vw;
    }

    #page2 .infoWrapper {
      max-width: 90vw;

      #info, .infoDiv {
        padding: 1rem 2rem;
      }
    }
  }

  @media screen and (max-width: 425px) {
    #page2 a {
      font-size: 2rem;
      translate: 0 -50%;
    }
  }
  